@import "@/styles/base-imports";

.iconText {
  user-select: none;
  display: flex;
  gap: 2rem;
  gap: 1rem;
  align-items: center;

  &.narrow {
    &>span {
      gap: 0.2rem;
    }
  }

  .text {
    width: 100%;
  }

  &>span {
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
    align-items: center;

    &>span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  :global {
    .icon {
      min-width: auto;
      width: 1em;
      flex-shrink: 0;
    }

    .popover-content {
      pointer-events: none;
    }
  }

  &.popoverActive {
    :global {
      .popover-content {
        pointer-events: all;
      }
    }
  }
}

// .popover {
//   :global {
//     .popover-content,
//     .is-popover-bottom .popover-content {
//       position: fixed !important;
//       display: block !important;
//       width: 90% !important;
//       top: 10vh !important;

//       @include tablet {
//         position: absolute;
//         display: inline-block;
//         width: max-content;
//         top: auto !important;
//       }
//     }
//   }
// }

.flippedH {
  transform: scaleX(-1);
}

.flippedV {
  transform: scaleY(-1);
}