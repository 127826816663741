@import "@/styles/variables";

.container {
  cursor: pointer;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 99;
  border-radius: 10px;
  box-shadow: 0 8px 16px #00000057;
  display: grid;
  place-content: center;
  background-color: $offwhite;
  width: 52px;
  height: 52px;
  opacity: 0;
  transition: opacity 0.5s ease;

  &.active {
    opacity: 1;
  }
}
