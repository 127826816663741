@import "@/styles/base-imports";

.container {
  min-height: 100dvh;

  @media print {
    background: white;
  }
}

.mainContent {
  padding: 0;

  @media print {
    margin-top: 0;
  }
}