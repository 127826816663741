.container {
  width: 100vw;

  @media print {
    display: none;
  }
}

.header {
  @media print {
    display: none;
  }
}
